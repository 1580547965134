import { useRouter } from 'next/router';
import { FC } from 'react';
import { Block, MainContainer } from '../../styles/basicStyles';
import { useTranslation } from 'next-i18next';
import { colors } from '../../styles/colors';
import { Line } from './styles';
import Metatags from '../../components/metatags';
import Typography from '../../components/typography';
import Button from '../../components/button';

const NotFound: FC = () => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <Block w='100%' mt={6} mb={8} position='relative'>
      <Line>
        <svg xmlns="http://www.w3.org/2000/svg" width="179" height="352" viewBox="0 0 179 352" fill="none">
          <path d="M290 8.5C259.309 -6.03787 224.22 10.4214 197.267 25.5824C160.145 46.4637 32.3201 194.812 12.5296 232.194C-2.63972 260.847 -5.24565 285.069 30.8321 294.422C61.5173 302.378 96.9461 291.499 122.209 273.815C127.308 270.246 148.946 254.582 137.8 247.514C125.893 239.963 112.526 247.558 102.822 254.835C81.5914 270.758 80.3055 282.482 69.5 306C59.1594 328.506 63.2394 341.949 91.5 347.5C136.827 356.403 179.703 327.589 220.838 313.878" stroke="#64FFE3" strokeOpacity="0.9" strokeWidth="4" strokeLinecap="round"/>
        </svg>
      </Line>
      <MainContainer>
        <Metatags title='404 || Codepoint' />
        <Block w='100%' pt={6} position='relative' zIndex={2}>
          <Typography 
            as='div' 
            fSize={4} 
            lHeight={4.5}
            mb={0.75}
            color={colors.purple}
          >
            404
          </Typography>
          <Typography 
            as='div' 
            fSize={2} 
            lHeight={2.5}
            fWeight={700}
            mb={0.75}
            color={colors.chineseBlack}
          >
            {t('404_TITLE')}
          </Typography>
          <Typography 
            as='div' 
            fSize={1.063} 
            lHeight={1.75}
            mb={1}
            color={colors.chineseBlack}
          >
            {t('404_MESSAGE')}
          </Typography>
          <Button
            text={t("BACK_TO_HOME_PAGE")}
            size="md"
            variant="primary"
            textColor={colors.white}
            bgColor={colors.purple}
            onClick={() => router.push('/')}
          />
        </Block>
      </MainContainer>
    </Block>
  );
};

export default NotFound;
