import { breakpoints } from "../../styles/breakpoints";
import styled from "styled-components";

export const Line = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  & path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation-duration: 2.5s;
    animation-name: dash;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
  
  @media ${breakpoints.xs} {
    & svg {
      width: 6.25rem;
      height: auto;
    }
  }
`;