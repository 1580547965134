import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import NotFound from '../views/notFound';

function Index() {
  return (
    <NotFound />
  );
}

export async function getStaticProps({ locale }: any) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['translation']))
    },
  };
}

export default Index;
